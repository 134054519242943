

.form-links {
	display: table;
	margin: 20px auto;
	color: #23527c;
	text-align: left;
	float: center;
}

.form-links li {
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
	border-left: 1px solid #23527c;
	text-align: left;
}

.form-links li:first-child {
	border-left: none;
}

.form-links li a {
	text-decoration: none;
	font-size: 15px;
	color: #23527c;
	text-align: left;
}

.form-links li a:hover {
	color: #23527c;
}

@media(max-width: 568px){
	.form-links {
		margin: 12px auto;
	}

	.form-links li {
		display: inline-block;
		padding-left: 8px;
		padding-right: 8px;
	}

	.form-links li a {
		font-size: 14px;
	}
}
@media(max-width:424px) {
	.form-links {
		margin: 12px auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form-links li {
		display: inline-block;
		margin: 4px auto;
		padding-top: 8px;
		padding-left: 8px;
		border-left: none;
	}

	.form-links li a {
		font-size: 14px;
	}
}
/*.disclaimer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dim-gray);
}

.disclaimer__menu {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1em;
  margin-bottom: 1em;
}

.disclaimer__menu-options {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 14px;
}

.disclaimer__menu-text {
  font-size: 12px;
  margin-bottom: 2em;
  text-align: justify;
  width: 85vw;
}

.disclaimer__copyright {
  font-size: 12px;
  margin-bottom: 2em;
}

.disclaimer__copyright-hidden {
  display: none;
}

.disclaimer__button-as-link {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  color: var(--dim-gray);
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}

@media screen and (min-width: 768px) {
  .disclaimer__container {
    color: var(--white);
    font-family: sans-serif;
  }

  .disclaimer__button-as-link {
    color: var(--white);
    font-weight: normal;
  }

  .disclaimer__copyright {
    display: none;
    font-size: 11px;
  }

  .disclaimer__menu {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70vw;
    margin-top: 0.5em;
  }

  .disclaimer__copyright-hidden {
    display: flex;
    order: 1;
    flex-basis: 43%;
    margin: 0;
  }

  .disclaimer__menu-options {
    order: 2;
    flex-basis: 53%;
    flex-direction: row;
    font-size: 9px;
    justify-content: end;
  }

  .disclaimer__menu-text {
    order: 3;
    flex-basis: 100%;
  }

  .disclaimer__menu-options > *:not(:last-child)::after {
    content: '|';
    margin-left: 8px;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1024px) {
  .disclaimer__copyright {
    font-size: 14px;
    flex-basis: 38%;
  }

  .disclaimer__menu-options {
    font-size: 14px;
    flex-basis: 59%;
  }
}

@media screen and (min-width: 1200px) {
  .disclaimer__copyright {
    font-size: 16px;
  }

  .disclaimer__copyright-hidden {
    flex-basis: 45%;
  }

  .disclaimer__menu-options {
    font-size: 16px;
    flex-basis: 52%;
  }
}*/
