.custom-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.8);
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.custom-loader__wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-loader__container {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 15px solid #f3f3f3;
  border-top: 15px solid turquoise;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-loader__text,
.custom-loader__percentage {
  color: turquoise;
  font-family: Arial, sans-serif;
  text-align: center;
  width: 100%;
}

.custom-loader__text {
  font-size: 0.8rem;
  margin-bottom: 2rem;
}

.custom-loader__percentage {
  font-size: 1.2rem;
  z-index: 1;
}

@media (min-width: 667px) {
  .custom-loader__text {
    font-size: 1rem;
  }

  .custom-loader__percentage {
    font-size: 1.5rem;
  }
}
