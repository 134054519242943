input {
	font-family: roboto,sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
input {
	display: block;
	height: 50px;
	padding: 21px 23px;
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #333;
	background-color: #fff;
	border: 1px solid #ccc;
}


input[name=zip]::-webkit-inner-spin-button {
	display: none;
}

input[name=zip]::-webkit-outer-spin-button,
input[name=zip]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type=number].input-error,
input[type=number].form-step__input-error {
	border: 1px solid red!important;
	color: #ea5555!important;
}

input[type=number].input-error::-webkit-input-placeholder,
input[type=number].form-step__input-error::-webkit-input-placeholder {
	color: red !important;
}

input[type=number].input-error:-moz-placeholder,
input[type=number].form-step__input-error:-moz-placeholder {
	color: red !important;
}

input[type=number].input-error::-moz-placeholder,
input[type=number].form-step__input-error::-moz-placeholder {
	color: red !important;
}

input[type=number].input-error:-ms-input-placeholder,
input[type=number].form-step__input-error:-ms-input-placeholder {
	color: red !important;
}


.radio-list {
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	overflow: hidden;
}

.radio-list li {
	border-bottom: 1px solid #ddd;
}

.radio-list li:last-child {
	border-bottom: 0;
}

.radio-list li.active {
	background-color: #f5f5f5;
}

.radio-list a {
	display: block;
	text-decoration: none;
}

.radio-button {
	position: relative;
	display: block;
	overflow: hidden;
}

.radio-button span,.radio-button input {
	display: inline-block;
	vertical-align: middle;
}

.radio-button span {
	padding: 17px 25px 16px 55px;
	color: #000;
	display: block;
}

.radio-button input {
	position: absolute;
	left: 0;
	width: 100%;
	visibility: hidden;
}

.radio-button .radiobutton {
	display: block;
	position: absolute;
	border: 1px solid #555;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	height: 24px;
	width: 24px;
	top: 13px;
	left: 16px;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.radio-button .radiobutton::before {
	display: block;
	position: absolute;
	content: "";
	border-radius: 100%;
	height: 14px;
	width: 14px;
	top: 4px;
	z-index: 102;
	left: 4px;
	margin: auto;
	transition: background .25s linear;
	-webkit-transition: background .25s linear;
}

.radio-button input[type=radio]:checked~.radiobutton {
	border: 1px solid #555;
}

.radio-button input[type=radio]:checked~.radiobutton::before {
	background: #555;
	margin: auto;
	transition: background .25s linear;
	-webkit-transition: background .25s linear;
}

.radio-button input[type=radio]:checked~span {
	background: #f5f5f5;
}

@media(max-width: 568px){
	.radio-button span {
		font-size: 14px;
	}
}