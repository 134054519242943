.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--white);
  border-radius: 10px;
  overflow: auto;
  width: 80%;
  max-height: 90%;
  position: relative;
}



html.popup-open,.popup-open body {
	overflow: hidden;
}

.popup.hide {
	display: none;
}

.popup {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	z-index: 1035;
	overflow: auto;
	background-color: #f8f8f8;
}

.popup .step-form-wrap {
	display: block;
	margin: 30px auto!important;
/*	margin-right: 30px auto!important;*/
	max-width: 43%;
}

.popup .step-form-wrap.confirmation-step {
	max-width: 90%;
}

.popup #confirmationRT {
	padding-right: 30%!important;
	height: 1000px;
}

.popup #renuant-conf {
	width: 100%;
	height: 100%;
}

.popup .form-top {
	display: table;
	width: 100%;
}

.popup .form-top div {
	display: table-cell;
	vertical-align: middle;
	width: 33.333%;
}

.popup .form-top .col-1 {
	text-align: left;
}

.popup .form-top .col-2 {
	text-align: center;
	order: 3;
}

.popup .form-top .col-3 {
	text-align: right;
}

.popup .form-top .logo img {
	max-width: 250px;
	width: 100%;
	vertical-align: middle;
}

.popup .form-top .steps {
	font-size: 16px;
	color: #777;
}

.popup .step-form {
	margin-top: 20px;
}

.popup .step-form .inner {
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 35px 65px 30px;
	position: relative;
	z-index: 2;
}
.popup .step.active[data-step="9"] + .inner,
.popup .step.active[data-step="10"] + .inner {
    padding-top: 95px;
}
.popup .step.active[data-step="9"] + .inner .form-header,
.popup .step.active[data-step="10"] + .inner .form-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
.popup .step.active[data-step="9"] + .inner button.step-button{
	margin-top: 0;
}

.popup .step {
	display: none;
}

.popup .step.active {
	display: block;
}

.popup .step-confirmation {
	text-align: center;
}

.popup .step-confirmation h2 {
	color: #000;
	font-size: 26px;
	margin-top: 25px;
	margin-bottom: 28px;
}

.popup .step-confirmation p {
	margin-bottom: 20px;
}

.popup .step-title {
	font-weight: 500;
	color: #000;
	margin-bottom: 13px;
}

.popup .form-footer {
	margin-top: 40px;
	display: block;
	text-align: center;
	margin-bottom: 80px;
}

.popup .form-footer .info {
	color: #000;
}

.popup .form-footer .footer-links {
	margin-top: 20px;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
}

.popup .form-footer .footer-links li {
	display: inline-block;
	margin-left: 10px;
	display: inline-block;
	padding-left: 10px;
	border-left: 1px solid #318dd4;
}

.popup .form-footer .footer-links li + li + li {
	margin-top: 20px;
}

.popup .form-footer .footer-links li:first-child,
.popup .form-footer .footer-links li:nth-child(3) {
	border-left: none;
	margin-left: 0;
	padding-left: 0;
}

.popup .form-footer .footer-links li a {
	text-decoration: none;
	font-size: 15px;
	color: #318dd4;
}

.popup .form-footer .footer-links li a:hover {
	color: #23527c;
}

.popup .form-header {
	background-color: var(--blue);
	margin-bottom: -1px;
	position: relative;
	z-index: 3;
}

.popup .form-header h3 {
	text-align: center;
	display: block;
	widows: 100%;
	color: #fff;
	font-weight: 700;
	padding-top: 25px;
	padding-bottom: 25px;
}

.popup .step-button {
	width: 100%;
	margin-top: 24px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	background-color: var(--blue);
	padding: 21px 36px;
	height: 60px;
	position: relative;
	font-weight: 400;
}

.popup .step-button .button-inner {
	transition: all .1s ease;
	-webkit-transition: all .1s ease;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: -3px;
	font-weight: 500;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	background-color: var(--blue);
	line-height: 60px;
}

.popup .step-button:hover .button-inner {
	top: 0;
	background-color: var(--blue);
}

.popup .step-bottom {
	padding-top: 22px;
}

.popup .step-bottom img {
	width: 18px;
	height: 22px;
}

.popup .step-bottom span {
	color: #777;
	margin-left: 13px;
	line-height: 19px;
	font-size: 15px;
}

.popup .step-bottom img,.popup .step-bottom span {
	display: inline-block;
	vertical-align: middle;
}

.popup .custom-submit__container {
	text-align: left;
}

.popup .custom-submit__container .info {
	margin-left: 0;
	font-size: 13px;
	color: #777777;
}

.popup .custom-submit__container #step-final-submit {
	color: white;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    line-height: 1;
}

.popup .street-info {
	font-size: 14px;
	margin-top: 10px;
	display: block;
	color: #000;
}

.popup .input-space {
	display: block;
	height: 27px;
}

.popup .select-wrap {
	margin-top: 15px;
}

.popup .select-wrap select,.popup .select-wrap .selectric {
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	font-size: 16px;
	background-color: #fff;
}

.popup .select-wrap select .label,.popup .select-wrap .selectric .label {
	font-size: 16px;
	height: 48px;
	line-height: 48px;
	margin: 0 38px 0 19px;
	color: #000;
}

.popup .select-wrap select .button,.popup .select-wrap .selectric .button {
	top: 6px;
	background-color: #fff;
}

.popup .select-wrap select .button:after,.popup .select-wrap .selectric .button:after {
	border: 6px solid transparent;
	border-top-color: #ddd;
	border-bottom: none;
}

.popup .select-wrap.changed .selectric .label {
	color: #000;
}

.popup .input-wrap {
	display: block;
	margin-bottom: 30px;
}

.popup .input-wrap label {
	color: #000;
	margin-bottom: 12px;
	display: block;
	font-size: 18px;
	font-weight: 500;
}

.popup .input-wrap input {
	width: 100%;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	margin-bottom: 0;
}

.popup .input-wrap.last {
	margin-bottom: 0;
}
.popup .form-top.clearfix {
	display: flex;
	align-items: center;
}

.popup .form-top.clearfix>*>* {
	float: left;
	text-align: left;
}

.popup .form-top.clearfix>div[class*=col-] {
	flex: 1 0 auto;
	width: auto!important;
	max-width: 100%;
	text-align: left;
	padding-bottom: 12px;
}

.popup .form-top.clearfix>div[class*=col-] * {
	text-align: left;
}

.popup .step-confirmation .confirmation {
	background-color: inherit;
	border: 0;
}

.popup .step-confirmation .confirmation>h2 {
	background-color: #49b57e;
	color: #fff;
	font-size: 18px;
	padding-top: 25px;
	padding-bottom: 28px;
	font-weight: 400;
	margin: 0;
}

.inner .errors {
    position: absolute;
    width: 100%;
    left: 0;
    top: 70px;
}

.inner .errors ~ .step.active,
.inner .errors ~ .input-wrap {
    margin-top: 3em;
}


@media only screen and (max-width:1199px) {
	.inner .step-back {
		right: 0px;
	}
}

@media only screen and (max-width:991px) {
	
	.popup .step-form-wrap {
		max-width: 90%;
	}
	.popup .step-button .btn-linebreak {
		display: block!important;
	}
}

@media only screen and (max-width:768px) {
	
	.popup .step-button .btn-linebreak {
		display: block!important;
	}
	
}

@media only screen and (max-width:568px) {
	.popup .form-top>div {
		padding: 0 8px 0 0;
	}
	
	.popup .step-form .inner {
		padding: 20px;
	}
	
	.inner .errors {
 		padding-left: 20px !important;
	}
	
	.popup .form-top .logo img {
		max-width: 160px;
		
	}
	.popup .form-top.clearfix *,
	.inner .step-back {
		font-size: 10px;
		top: -54px;
	}
	.inner .step-back {
		font-size: 13px;
	}

	.popup .form-top>.col-3 {
		font-size: 10px!important;
	}
	
	.popup .custom-submit__container {
		word-spacing: 0px;
		line-height: 1.3;
	}

}
