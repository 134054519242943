.top-bar-and-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: var(--white);
  background: rgba(0, 0, 0, 0.5);
}

.top-bar-and-footer.top-bar-and-footer__bottom-gray {
  background: var(--dark-gray);
}

.top-bar-and-footer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: var(--dark-gray);
}

.top-bar-and-footer__number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  gap: 5px;
  cursor: pointer;
  font-size: 18px;
}

.top-bar-and-footer__number-text {
  text-decoration: underline;
}

.top-bar-and-footer__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--dark-gray);
}

.top-bar-and-footer__image {
  height: auto;
  max-width: 236px;
  object-fit: contain;
}


#site-header {
	padding-top: 33px;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 100;
}

#site-header .logo {
	display: block;
	position: relative;
}

#site-header .logo .image {
	width: 310px;
	height: auto;
}

#site-footer {
	border-top: 1px solid #eee;
	padding-top: 33px;
	padding-bottom: 33px;
}

#site-footer p {
	font-size: 12px;
	line-height: 16px;
	color: #777;
	display: block;
	text-align: center;
}

#site-footer .footer-links {
	display: table;
	margin: 40px auto 0;
	color: #000;
}

#site-footer .footer-links li {
	display: inline-block;
	margin-left: 22px;
	margin-right: 22px;
}


@media screen and (min-width: 768px) {
  .top-bar-and-footer {
    background: rgba(0, 0, 0, 0.5);
  }

  .top-bar-and-footer__container {
    justify-content: space-between;
    flex-direction: row;
    background-color: transparent;
    padding: 0;
    height: 80px;
  }

  .top-bar-and-footer__number-container {
    justify-content: center;
    margin-top: 0;
    width: auto;
    height: auto;
    padding: 15px;
    font-size: 20px;
  }

  .top-bar-and-footer__link {
    width: calc(100vw * 0.35);
  }
}

@media screen and (min-width: 1200px) {
  .top-bar-and-footer__link {
    width: calc(100vw * 0.2);
  }
}

@media(max-width: 768px){
	
	#site-header {
		padding-left: 20px;
		padding-right: 20px;
	}
	#site-header .logo .image {
		display: block;
		width: 210px;
		height: auto;
	}

	#site-header .logo {
		margin-bottom: 20px;
	}
	#site-footer .footer-links {
		display: block;
		width: 100%;
		text-align: center;
	}

	#site-footer .footer-links li:first-child {
		display: block;
		width: 100%;
		margin-bottom: 20px;
		text-align: center;
		margin-left: 0;
		margin-right: 0;
	}
}

@media(max-width: 568px){
	#site-header {
		padding-top: 20px;
	}
	#site-footer .footer-links li {
		margin-left: 5px;
		margin-right: 5px;
		font-size: 14px;
	}
}