.start-form {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    position: relative;
}
.start-form-bottom {
    margin-top: 85px;
}
input,
select {
    display: block;
    height: 50px;
    padding: 21px 23px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
}

.start-form input[name=zip_code],
.start-form input[type=number] {
    display: inline-block;
    float: left;
    height: 60px;
    min-width: 215px;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
	outline: 0 none;
}

.why .start-form-top input[type=number] {
    border: 1px solid #ddd;
    background-color: #fff;
}

button {
    display: inline-block;
    padding: 16px 36px;
    font-weight: 500;
    background-color: var(--blue);
    color: #fff;
    font-size: 18px;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
}
.start-form button {
    float: left;
    margin-left: -1px;
    height: 60px;
    padding: 16px 45px;
}
.start-form .error {
    position: absolute;
    color: red;
    left: 23px;
    bottom: -14px;
    font-size: 14px;
    display: none;
}
input[type=number].input-error {
    border: 1px solid red!important;
    color: #ea5555!important;
}


input[name=zip]::-webkit-inner-spin-button {
	display: none;
}

input[name=zip]::-webkit-outer-spin-button,
input[name=zip]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.hintArea {
    background: #f7f7f3 url(../../../assets/img/light-bulb-icon.svg) 13px 13px no-repeat;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    margin: 2em auto 0;
    max-width: 450px;
    padding: 15px 25px 15px 60px;
    position: relative;
	width: 100%;
}
.hintArea .hint__title {
    font-weight: 700;
    margin-bottom: 0 0 2px;
}

@media(max-width: 600px){
	
	.start-form {
		margin-top: 20px;
	}
	
}