

.start-form {
	display: table;
	margin-left: auto;
	margin-right: auto;
	margin-top: 48px;
	position: relative;
}

.start-form input[name=zip_code],
.start-form input[type=number] {
	display: inline-block;
	height: 60px;
	min-width: 215px;
	border: 1px solid #f5f5f5;
	background-color: #f5f5f5;
}

.start-form input[name=zip]::-webkit-input-placeholder,
.start-form input[type=number]::-webkit-input-placeholder {
	color: #777;
}

.start-form input[name=zip]:-moz-placeholder,
.start-form input[type=number]:-moz-placeholder {
	color: #777;
}

.start-form input[name=zip]::-moz-placeholder,
.start-form input[type=number]::-moz-placeholder {
	color: #777;
}

.start-form input[name=zip]:-ms-input-placeholder,
.start-form input[type=number]:-ms-input-placeholder {
	color: #777;
}

.start-form input[name=zip]::-webkit-inner-spin-button,
.start-form input[name=zip]::-webkit-outer-spin-button input[type=number]::-webkit-inner-spin-button,
.start-form input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.start-form button {
	float: left;
	margin-left: -1px;
	height: 60px;
	padding: 16px 45px;
}

.start-form .error,
.start-form .form-step__error-box{
	position: absolute;
	color: red;
	left: 23px;
	bottom: -14px;
	font-size: 14px;
	display: none;
}
.start-form .form-step__input-error ~ .form-step__error-box {
	display: inline-block !important;
	display: none !important;
}

.step-back {
	text-decoration: none;
	float: right;
}
.inner .step-back {
	position: absolute;
	right: 61px;
	top: -66px;
}
.main-form {
  width: 100%;
  box-sizing: border-box;
  margin-top: 1em;
}
.main-form__tag {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.main-form__title {
  color: var(--charcoal);
  font-size: 19px;
  text-align: center;
  text-shadow: none;
  margin-top: 2px;
  margin-bottom: 4px;
}

.main-form__subtitle {
  color: var(--kelly-green);
  font-size: 15px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 0;
}

.main-form__step-container {
  background-color: var(--white);
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}

.main-form__step-info {
  font-size: 15px;
  color: var(--kelly-green);
}

.main-form__img-container {
  display: flex;
  justify-content: center;
}

.main-form__img {
  height: 60px;
  margin-top: 5em;
}

.main-form__list {
  margin: 0;
  padding: 1em 0 0 1em;
  list-style-position: outside;
}

.main-form__list li {
  color: black;
  margin: 0.5em 0;
  list-style-type: disc;
  padding: 0;
  list-style-position: outside;
  font-weight: 600;
}

.main-form__list {
  margin: 0;
  padding: 3rem;
  list-style-position: outside;
}

.errors {
    background-color: #ea5555;
    margin-bottom: -1px;
    position: relative;
    z-index: 3;
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
    font-size: 14px;
    display: block;

    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
    font-size: 14px;
    display: block;
}



.tooltip {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	position: relative;
	cursor: pointer;
	z-index: 102;
}

.tooltip .tooltip-icon {
	display: inline-block;
	vertical-align: middle;
}

.tooltip .tooltip-icon img {
	width: 26px;
	height: 26px;
}

.tooltip .tooltip-content {
	position: absolute;
	background: #fff;
	color: #000;
	font-size: 14px;
	font-weight: 400;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	padding: 10px;
	width: 280px;
	right: -300px;
	line-height: 1.4;
	top: -25px;
	border: 1px solid #ddd;
	visibility: hidden;
}

.tooltip .arrow-wrap {
	position: absolute;
	left: -12px;
	width: 12px;
	height: 24px;
	top: 27px;
	overflow: hidden;
	display: inline-block;
}

.tooltip .arrow {
	width: 24px;
	height: 24px;
	display: inline-block;
	border: 1px solid #ddd;
	background: #fff;
	margin-left: 5px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
}

.tooltip:hover .tooltip-content {
	visibility: visible;
}

@media(max-width:568px) {
	.tooltip .tooltip-content {
		width: 280px;
		right: -123px;
		line-height: 1.4;
		top: 39px;
	}

	.tooltip .arrow-wrap {
		top: -12px;
		left: 130px;
		width: 24px;
		height: 12px;
	}

	.tooltip .arrow {
		margin-left: 0;
		margin-top: 5px;
	}
}

@media (min-width: 576px) {
  .main-form {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .main-form {
    padding: 1em 1em 1em 5em;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .main-form__list {
    margin: 0;
    padding: 1em 0 0 1em;
    list-style-position: outside;
  }

  .main-form__list li {
    color: #fff;
    margin: 0.5em 0;
    list-style-type: disc;
    padding: 0;
    list-style-position: outside;
    font-weight: 600;
  }

  .main-form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .main-form__title {
    color: var(--white);
    font-size: 54px;
    font-weight: 900;
    text-align: left;
    margin-top: 0;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
  }

  .main-form__subtitle {
    color: var(--white);
    font-size: 26px;
    line-height: 38px;
    text-align: left;
    margin-top: 0;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  }

  .main-form__step-container {
    width: 460px;
    padding: 20px 40px 30px 40px;
  }

  .main-form__step-info {
    text-align: left;
    font-size: 18px;
    line-height: 38px;
  }

  .main-form__img-container {
    justify-content: right;
  }
}