@import "https://fonts.googleapis.com/css?family=Roboto:400,500,700";

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
	display: block;
}

body {
	line-height: 1;
	background-color: #FFFFFF;
}

ol,ul {
	list-style: none;
	}

blockquote,q {
	quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}/*!* Bootstrap v3.3.7 (http://getbootstrap.com)
* Copyright 2011-2016 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)*/

.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}


body {
	font-family: roboto,sans-serif;
	color: #152c5d;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,body {
	height: 100%;
	position: relative;
	overflow-x: hidden;
}



* {
	box-sizing: border-box;
}



h1,h2,h3,h4,h5 {
	font-weight: 700;
}

h1 {
	font-size: 38px;
}

h2 {
	font-size: 36px;
	line-height: 1.2;
}

h3 {
	font-size: 18px;
	line-height: 1.2;
}

p {
	font-size: 16px;
	line-height: 24px;
	color: #777;
}

a {
	color: #318dd4;
	text-decoration: underline;
}

a:hover {
	color: #0d5b96;
}

strong {
	font-weight: 700;
}

.hero {
	position: relative;
	background-color: #0c1e45;
	z-index: 99;
	background-size: cover;
	background-position: center;
	background-image: url('../../assets/img/hero.jpg');
}
.hero .text {
	max-width: 750px;
	display: table;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 25rem;
	margin-top: 10rem;
}

.hero .inner-wrap {
	display: table;
	width: 100%;
	height: 100vh;
	text-align: center;
}

.hero .inner-wrap .inner {
	display: table-cell;
	vertical-align: middle;
}

.hero h1 {
	color: #fff;
	margin-bottom: 20px;
	text-shadow: 0 1px 2px rgba(0,0,0,.5);
	text-transform: uppercase;
}

.hero h1.subheader {
	margin-bottom: 37px;
}

.hero p {
	color: #fff;
	font-size: 22px;
}


.hero p.fedText {
	margin-bottom: 25px;
}

.why {
	padding-bottom: 90px;
}

.why .section-top .inner {
	display: table;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.why .section-top h2 {
	font-weight: 700;
	padding-top: 60px;
	position: relative;
}

.why .section-top h2:after {
	content: "";
	height: 2px;
	width: 60px;
	background-color: #ebf1ff;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	margin-bottom: 25px;
}

.why .section-top p {
	margin-bottom: 30px;
}

.why .col {
	text-align: center;
	margin-top: 30px;
}

.why .col h3 {
	min-height: 60px;
	font-weight: 700;
	color: #000;
}

.why .col .col-icon {
	width: 156px;
	height: 156px;
	margin-bottom: 30px;
}





.landing-page-content .content {
	text-align: left;
	background-color: #fff;
	padding: 16px;
}

.landing-page-content .content h1 {
	font-size: 1.75rem;
	margin-bottom: .5rem;
	font-weight: 600;
	line-height: 1.2;
	color: #000;
}

.landing-page-content .content h2 {
	font-size: 1.53125rem;
	margin-bottom: .5rem;
	font-weight: 600;
	line-height: 1.2em;
	color: #000;
}

.landing-page-content .content h3 {
	font-size: 1.3125rem;
	margin-bottom: .5rem;
	font-weight: 600;
	line-height: 1.2em;
	color: #000;
}

.landing-page-content .content h4 {
	font-size: 1.09375rem;
	margin-bottom: .5rem;
	font-weight: 600;
	line-height: 1.2;
	color: #000;
}

.landing-page-content .content p {
	font-size: 14px;
	line-height: 1.5;
	color: #495057;
	margin-top: 0;
	margin-bottom: 10px;
}

.landing-page-content .header {
	padding: 20px;
	background: #49b57e;
	color: #fff;
	width: 100%;
}

.landing-page-content .header h3 {
	margin-top: 0;
	text-transform: uppercase;
	font-size: 24px;
}

.landing-page-content .content table,th,td {
	border: 1px solid #000!important;
	border-collapse: collapse!important;
	font-size: 14px!important;
	text-align: left;
	padding: 5px;
	color: #495057;
	line-height: 1.2em;
}

.landing-page-content .content th {
	color: #495057;
	font-weight: 600;
}

.landing-page-content .content ol li {
	margin-bottom: 10px!important;
	list-style-type: decimal!important;
	color: #495057;
}

.landing-page-content .content ul li {
	margin-bottom: 10px!important;
	list-style-type: disc!important;
	color: #495057;
}

.landing-page-content .content ul {
	margin-left: 40px!important;
	width: 85%;
	font-size: 14px;
	line-height: 1.2;
}

.landing-page-content .content ol {
	margin-left: 40px!important;
	width: 85%;
	font-size: 14px;
	line-height: 1.2;
}

.landing-page-content .content em {
	font-style: italic;
}

@media(max-width:900px) {
	html,body {
		overflow-x: hidden;
	}
}

@media(max-width:768px) {
	h2 {
		font-size: 24px;
	}

	.why {
		padding-bottom: 40px;
	}

	.hero h1 {
		font-size: 30px;
		line-height: 1.3;
		margin-bottom: 15px;
	}

	.hero .inner-wrap {
		padding-top: 120px;
		padding-bottom: 60px;
		padding-left: 20px;
		padding-right: 20px;
		height: auto;
		min-height: 100px;
	}

	.hero .inner-wrap .inner {
		display: block;
	}	

	#zipcodeheader {
		font-size: 25px;
		padding-bottom: 85px;
	}
}

@media(max-width:600px) {

	.hero {
		background-image: url(../img/hero.jpg);
	}

	.hero .container {
		height: 100%;
	}

	.hero .container .row {
		height: 100%;
		display: flex;
	}

	.hero .container .row .inner-wrap {
		height: 100%;
	}

	.hero .container .row .inner-wrap .inner {
		height: 100%;
	}

	.hero .container .row .inner-wrap .inner .text {
		display: flex;
		flex-direction: column;
		margin-top: 0;
		padding-bottom: 0;
		/*height: calc(100vh - 310px);*/
		height: calc(66vh);
	}

	.hero .container .row .inner-wrap .inner .text p {
		margin-top: auto;
		margin-bottom: 0;
		color: #000;
		max-width: 320px;
		margin-left: auto;
		margin-right: auto;
	}
	.hero .container .row .inner-wrap .inner .text p.fedText {
		color: #FFF;
		font-size: 16px;
		margin-bottom: 15px;
		margin-top: -5px;
	}

	.hero .container .row .inner-wrap .inner .text div.form {
		margin-bottom: 0;
	}

	.hero .container .row .inner-wrap .inner .text div.form input {
		border-color: #000;
		text-align: center;
		margin-bottom: 0;
		width: calc(100% - 2px);
	}
	.why .start-form-top input[type=number],
	.start-form button {
		width: 100%;
		margin-bottom: 0;
	}

	.hero {
		background-image: url(../img/hero.jpg);
		background-repeat: no-repeat;
		background-size: auto 50%;
		background-position: 25% top;
		background-color: rgba(196,198,251,.18);
		height: 100vh;
	}

}

@media(max-width:568px) {
	

	.hero .inner-wrap {
		padding-top: 110px;
	}

	input,select {
		padding: 21px 15px;
	}

	.hero h1 {
		font-size: 135%;
	}

	#subheader {
		font-size: 100%;
	}

	h3 {
		font-size: 16px;
	}

	

	.popup .input-wrap {
		margin-bottom: 20px;
	}



	.why .col .col-icon {
		width: 120px;
		height: auto;
	}

	#renuant-cw .step-form-wrap {
		padding-right: 50%;
		padding-left: 0%;
		margin-top: 30px;
		margin-right: auto;
		margin-bottom: 30px;
		margin-left: auto;
	}

	

	
}

